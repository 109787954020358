import Model from "../Model";

class Hello extends Model{

    buildUrl ({ action }) {
        return [action];
    }

    // async all() {
    //     return this.makeRequest({
    //         url: process.env.VUE_APP_API_URL + '/hello',
    //         method: 'GET',
    //     });
    // }

    // buildUrl ({ param }) {
    //     return ['hello', param];
    // }
    //

    all(config = {}) {
        console.log(config);
        return this.request({ method: 'GET', action: 'hello', ...config });
    }

    greetings(){
        return this.makeRequest({
            url: process.env.VUE_APP_API_URL + '/hello',
            method: 'GET',
        });
    }

}

export default new Hello;